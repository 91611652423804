import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, fontSizes, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, SCHEDULE_DEMO_LINK } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import ExploreFullPlatform from 'components/GetStartedComponent'
import Button from 'components/Button'

import imageHeader from 'static/images/solutions_multi_location_brands/header_picture.webp'

import SecurePlatformComponent from '../components/SecurePlatformComponent'
import GetStartedComponent from '../components/GetStartedComponent'
import ExpectComponent from '../components/ExpectComponent'
import IntegrationsComponent from '../components/IntegrationsComponent'
import FeaturesComponent from '../components/FeaturesComponent'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const TrialButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  margin-right: 10px;
`

const StyledScheduleDemoButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const SolutionsMultiLocationBrands = () => {
  return (
    <App fullHeader>
      <SEO
        title="Effectively Manage Multi-Location Brands"
        description="Create content across your locations, ensure consistent brand voice and style. Vista Social social media tools will ensure you get your work done quickly and efficiently. Gain insights necessary to understand the impact of your marketing efforts."
        path="/multi-location-brands/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '485px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Effectively Manage Social Media For{' '}
                    <H1
                      as="span"
                      fontSize="4xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Multi-Location Brands
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Create content across multiple business locations, and ensure consistent brand voice and style.
                      Vista Social's social media management tools will ensure you get your work done quickly and
                      efficiently. Gain insights necessary to understand the impact of your marketing efforts.
                    </H2>
                  </Flex>

                  <Flex mt="l" pt="m">
                    <Flex
                      height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
                      width="100%"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <TrialButton as="a" href="/pricing" target="_blank" rel="noreferrer noopener nofollow">
                        Start your free trial
                      </TrialButton>

                      <StyledScheduleDemoButton
                        as="a"
                        href={SCHEDULE_DEMO_LINK}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Request a demo
                      </StyledScheduleDemoButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="467px"
                  alt="Effectively Manage Multi-Location Brands"
                />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <SecurePlatformComponent />

      <GetStartedComponent />

      <ExpectComponent />

      <IntegrationsComponent />

      <FeaturesComponent title="Multi-Location Brands" />

      {/* <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.enterprise} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper> */}

      <ExploreFullPlatform />

      <Footer />
    </App>
  )
}

export default SolutionsMultiLocationBrands
